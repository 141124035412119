const ServicesMapping = [
  {
    imgsrc:
      "https://plus.unsplash.com/premium_photo-1661517335128-2bcf290d58f6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHRlYW13b3JrfGVufDB8fDB8fHww",
    title: "Web development",
    description:"We specialize in developing websites tailored to meet the unique needs of your business."
  },
  {
    imgsrc:
      "https://images.unsplash.com/photo-1609921141835-710b7fa6e438?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8YXBwJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D",
    title: "App development",
    description:"We offer fully customized mobile app development tailored to your business needs."
  },
  {
    imgsrc:
      "https://images.unsplash.com/photo-1629904853716-f0bc54eea481?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D",
    title: "Software development",
    description:"We are a team of top software engineers from across the country, delivering the most optimized software solutions."
  },
  {
    imgsrc:
      "https://images.unsplash.com/photo-1588075592446-265fd1e6e76f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fHNjaG9vbHxlbnwwfHwwfHx8MA%3D%3D",
    title: "School management",
    description:"We provide school management software designed to streamline all administrative and academic processes online."
  },
  {
    imgsrc:
      "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGhlbHBpbmclMjBidXNpbmVzc3xlbnwwfHwwfHx8MA%3D%3Dps://i.pinimg.com/originals/d6/ca/85/d6ca85481300d2a5e73ab3c48a088a96.png",
    title: "Master Class for student",
    description:"We provide one-to-one master classes for students, ensuring personalized attention and tailored learning experiences."
  },
  {
    imgsrc:
      "https://images.unsplash.com/photo-1679974172314-417ce87540c5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDMxNHxhZXU2ckwtajZld3x8ZW58MHx8fHx8",
    title: "Career Counselling",
    description:"With the help of top minds in India, we provide career counselling to ensure a brighter future for students."
  },
];

export default ServicesMapping;
