import React from "react";

const CarouselComponent = (props) => {
  return (
    <div>
      <section id="header" className="d-flex align-items-center">
        <div className="container-fluid nav-bg">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-md-6 mt-5 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                  <h1>
                    {" "}
                    {props.caption}{" "}
                    <strong className="brand-name">{props.description}</strong>
                  </h1>
                  <h2 className="my-3">{props.text}</h2>
                  <div className="mt-3">
                    <a href="#contact_us">
                    <button type="button" class="btn btn-primary btn-lg">
                      Request A Free Consultation
                    </button>
                    </a>
                  </div>
                </div>

                <div className="col-lg-6 order-1 order-lg-2 header-img">
                  <img
                    className="img-fluid animated"
                    src={props.image}
                    alt="home img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CarouselComponent