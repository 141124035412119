import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Carousel from "react-bootstrap/Carousel";

import CourrosoulStaticData from "./constants/corrosoul-data";
import CarouselComponent from "./carousel-component";
import About from "../about";
import Services from "../services";
import ContactUs from "../contact";
import Footer from "./footer";

function Home() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Helmet>
        <title>OnlineJaoo</title>
        <meta
          name="description"
          content="Welcome to OnlineJaoo.com. Discover our innovative software solutions, learn about our services, and get in touch with us."
        />
      </Helmet>
      <main id="home_page">
        <section aria-labelledby="carousel-heading">
          <h1 id="carousel-heading" className="sr-only">
            Highlights of Our Solutions
          </h1>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            interval={3000}
            pause={false}
          >
            {CourrosoulStaticData.map((slide) => {
              return (
                <Carousel.Item key={slide?.key}>
                  <CarouselComponent {...slide} />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </section>

        <section id="about-section">
          <About />
        </section>

        <section id="services-section">
          <Services />
        </section>

        <section id="contact-section">
          <ContactUs />
        </section>

        <footer>
          <Footer />
        </footer>
      </main>
    </>
  );
}

export default Home;
