import React from "react";
import Logo from '../../Image/logo_lightblue.png'
import './styles.css'

export default function Navbar() {
  const Rendermenu = () => {
    return (
      <>
        <li className="nav-item">
          <a
            className="nav-link"
            activeClassName="is-active"
            exact={true}
            aria-current="page"
            href="#home_page"
          >
            Home
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            activeClassName="is-active"
            exact={true}
            href="#about_us"
          >
            About
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            activeClassName="is-active"
            exact={true}
            href="#our_services"
          >
            Services
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            activeClassName="is-active"
            exact={true}
            href="#contact_us"
          >
            Contact
          </a>
        </li>
      </>
    );
  };
  return (
    <div>
      <div className="container-fluid nav_bg">
        <div className="row">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container-fluid">
                
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                  <img src={Logo} alt="logo" className="logo-top"/>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <Rendermenu />
                  </ul>
                </div>
              </div>
            </nav>
          </div>
      </div>
    </div>
  );
}
