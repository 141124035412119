import React from "react";

export default function Card(props) {
  return (
    <>
      <div className="col-md-4 col-10 mx-auto" style={{alignItem: 'center', justifyContent:'center',display:'flex'}}>
        <div
          className="card"
          style={{
            width: "18rem",
            border: "none",
          }}
        >
          <img src={props.imgsrc} class="card-img-top" alt="..." />
          <div className="card-body">
            <h5 className="card-title font-weight-bold">{props.title}</h5>
            <p className="card-text">
              {props.description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
