import React from "react";
import Card from "./card";
import ServicesMapping from "./constants/services-mapping";

const Services = () => {
  return (
    <div id='our_services' className="my-5">
      <h1 className="text-center">Our Services</h1>
      <br />
      <br />
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-8" style={{ flex: 1 }}>
            <div className="row gy-5">
              {ServicesMapping.map((value, idx) => {
                return (
                  <Card
                    imgsrc={value.imgsrc}
                    title={value.title}
                    description={value.description}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
