import "./about.css";
import { Helmet } from "react-helmet";

function About() {
  return (
    <section className="about-container" id="about_us">
      <Helmet>
        <title>About Us | Online Jao</title>
        <meta
          name="description"
          content="Learn about OnlineJaoo.com and our innovative software solutions tailored for various industries. Our team is dedicated to delivering top-notch products and services."
        />
      </Helmet>
      <article className="about-content">
        <header className="about-header">
          <h1>About Us</h1>
          <br />
          <br />
          <h2>Welcome to OnlineJaoo.com</h2>
          <p>
            Welcome to OnlineJaoo.com, where innovation meets excellence in
            software solutions! At OnlineJaoo.com, we specialize in providing
            cutting-edge software solutions tailored to meet the unique needs of
            businesses across various industries. Our team of skilled
            professionals is dedicated to delivering top-notch software products
            and services that empower organizations to thrive in today's dynamic
            digital landscape. Driven by a passion for innovation, we are
            committed to staying ahead of the curve in technology trends and
            advancements. Whether you're looking for custom software
            development, mobile app development, web application development, or
            software consulting services, we have the expertise and experience
            to turn your ideas into reality. What sets us apart is our
            customer-centric approach. We believe in building long-term
            partnerships with our clients, understanding their challenges, and
            delivering solutions that exceed their expectations. Our
            collaborative process ensures that every project is executed with
            precision and attention to detail, resulting in high-quality,
            scalable, and user-friendly software solutions. At OnlineJaoo.com,
            we value integrity, transparency, and reliability. We strive to
            foster a culture of excellence where innovation thrives, and our
            clients' success is our top priority. With our proven track record
            of delivering successful projects on time and within budget, you can
            trust us to be your trusted technology partner. Join us on a journey
            of innovation and transformation. Let's create something
            extraordinary together!
          </p>
        </header>
        <div className="about-info"></div>
      </article>
    </section>
  );
}

export default About;
