import React from "react";
import "./cont.css";
import { Helmet } from "react-helmet";

export default function ContactUs(props) {
  return (
    <div id="contact_us">
      <Helmet>
        <title>Contact Us | Online Jaoo</title>
        <meta name="description" content="Get in touch with OnlineJaoo.com for any inquiries or support. Contact us through phone, email, or visit our office in Kolkata, West Bengal." />
      </Helmet>
      <section className="contact" id="contact">
        <div className="container">
          <div className="heading text-center">
            <h2>
              Contact
              <span> Us </span>
            </h2>
            <p>
              Unlocking Solutions to Complex Business Challenges:
              <br />
              Welcome to Our Solver Community!
            </p>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="title">
                <h3>Contact Detail</h3>
                <p>
                  AB-52, Street Number 89, Action Area I, Newtown, Kolkata, New
                  Town, West Bengal 700156
                </p>
              </div>
              <div className="content">
                <div className="info">
                  <i className="fas fa-mobile-alt" aria-hidden="true"></i>
                  <h4 className="d-inline-block">
                    PHONE :
                    <br />
                    <span>+919669113670</span>
                  </h4>
                </div>
                <div className="info">
                  <i className="far fa-envelope" aria-hidden="true"></i>
                  <h4 className="d-inline-block">
                    EMAIL :
                    <br />
                    <span>admin@onlinejaoo.com</span>
                  </h4>
                </div>
                <div className="info">
                  <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                  <h4 className="d-inline-block">
                    ADDRESS :
                    <br />
                    <span>1724, AK Block, New Town, Kolkata</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <form>
                <div className="row">
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      aria-label="Name"
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      aria-label="Email"
                    />
                  </div>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      aria-label="Subject"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows="5"
                    id="comment"
                    placeholder="Message"
                    aria-label="Message"
                  ></textarea>
                </div>
                <button className="btn btn-block" type="submit">
                  Send Now!
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
