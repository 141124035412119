const CourrosoulStaticData = [
  {
    key: 1,
    caption: "Driven By Innovation",
    description: "& Technology!",
    image:
      "https://img.freepik.com/free-vector/multitasking-concept-illustration-character_23-2148403716.jpg?size=626&ext=jpg",
    text: "OnlineJaoo is one of the top software development company in India with 2+ years of experience serving leading enterprises in India.",
  },
  {
    key: 2,
    caption: "We Make Your Work",
    description: "Simple!",
    image:
      "https://img.freepik.com/free-vector/business-team-brainstorm-idea-lightbulb-from-jigsaw-working-team-collaboration-enterprise-cooperation-colleagues-mutual-assistance-concept-pinkish-coral-bluevector-isolated-illustration_335657-1651.jpg?size=626&ext=jpg&ga=GA1.1.1518243346.1642118400",
    text: "We pride ourselves on our after-sales client-care including our guarantees, staff-training and onsite/offsite support.",
  },
  {
    key: 3,
    caption: "Grow your business with",
    description: "Onlinejaoo.com",
    image:
      "https://img.freepik.com/free-vector/multitasking-concept-illustration-character_23-2148403716.jpg?size=626&ext=jpg",
    text: "We are a team comprised of highly skilled engineers who have graduated from prestigious institutions such as IIT/NIT. Our expertise lies in delivering innovative solutions aimed at enhancing the operational efficiency and productivity of your business.",
  },
];

export default CourrosoulStaticData;
